import PropTypes from "prop-types";
import styled from "styled-components";
import Tag from "./Tag";
import Photo from "./Photo";
import { truncateText } from "utilities/TruncateText";
import Tooltip from "components/UI/Tooltip";
import moment from "moment";

const Wrapper = styled.div`
    display: flex;
    width: 100%;
    > * {
        cursor: ${(props) => (props.enableClick ? "pointer" : "initial")};
    }
`;

const ProfilePicture = styled(Photo)`
    width: 3rem;
    height: 3rem;
    border-radius: 2rem;
    margin: auto 0.5rem;
    cursor: pointer;
`;

const Detail = styled.div`
    display: grid;
    width: 100%;
`;

const Main = styled.div`
    display: flex;
    color: ${global.config.colors.black};
    font-family: ${global.config.fonts.families.medium};
    font-size: ${global.config.fonts.sizes.small};
    width: 100%;
`;
const SubText = styled.div`
    color: ${global.config.colors.gray2};
    font-family: ${global.config.fonts.families.light};
    font-size: ${global.config.fonts.sizes.xsmall};
    padding: 0;
    margin: 0;
    width: 100%;
`;

const TagWrapper = styled.div`
    margin-left: 0.3rem;
    height: min-content;
`;

const Title = styled.div`
    font-size: 9px;
    font-family: ${global.config.fonts.families.light};
    color: ${global.config.colors.gray2};
`;

const Office = styled.div`
    font-size: 9px;
    font-family: ${global.config.fonts.families.light};
    color: ${global.config.colors.gray2};
`;

const Name = styled.div`
    width: 180px;
    display: flex;
    align-items: center;  
    color: ${global.config.colors.black};
    font-family: ${global.config.fonts.families.medium};
    font-size: ${global.config.fonts.sizes.small};
    padding-left: 0.75rem;
    padding-top: 0.25rem;
`;

const Details = styled.div`
    width: 120px;
    display: grid;
`;


const Profile = ({
    id,
    name,
    title,
    location,
    image,
    onClick,
    departureDate,
    employeeStatus,
    ...props
}) => {

    const freelance = employeeStatus === "Freelancer";

    const handleOnclick = () => {
        if (onClick) onClick();
    };

    return (
        <Wrapper
            onClick={handleOnclick}
            enableClick={id}
            className={props.className}
        >
            {/* <ProfilePicture url={image} clickable={id} /> */}
            <Name>
                {truncateText(name, 25)}
                {freelance && departureDate && (
                    <Tooltip
                        placement="bottom"
                        title={
                            "Departure Date: " +
                            `${moment(departureDate).format("MMM D, yyyy")}`
                        }
                    >
                        <TagWrapper>
                            <Tag>
                                <span>F</span>
                            </Tag>
                        </TagWrapper>
                    </Tooltip>
                )}
                {freelance && !departureDate && (
                    <TagWrapper>
                        <Tag>
                            <span>F</span>
                        </Tag>
                    </TagWrapper>
                )}
            </Name>
            <Details>
                <Title>{truncateText(title, 25)}</Title>
                <Office>{truncateText(location, 25)}</Office>
            </Details>
        </Wrapper>
    );
};

Profile.defaultProps = {
    name: "-",
    title: "-",
    location: "-",
    image: "/assets/images/default-profile.svg",
    freelance: false,
    departureDate: null,
    onClick: void 0,
};

Profile.propTypes = {
    name: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    location: PropTypes.string.isRequired,
    image: PropTypes.string,
    freelance: PropTypes.bool,
    departureDate: PropTypes.string,
    onClick: PropTypes.func,
};

export default Profile;
