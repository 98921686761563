import React, { useContext, useState } from "react";
import styled from "styled-components";
import { Row, Col } from "react-bootstrap";
import EmployeeDrawer from "./EmployeeDrawer";
import Profile from "components/UI/Profile";
import Pills from "./Pills";
import DownArrow from "components/Icon/DownArrow";
import More from "./More";
import Checkbox from "components/design-system/controls/checkbox/Checkbox";
import EmployeeContext from "context/Allocations/EmployeeContext";
import GenericSidebarPortal from "components/Modal/Common/GenericSidebarPortal";
import EmployeeGridSelectionContext from "context/Allocations/EmployeeGridSelectionContext";
import CustomGroupContext from "context/Allocations/CustomGroupContext";
import Context from "context/Allocations/Context";
import getEmployeeKey from "./utils/getEmployeeKey";
import ActionPermissionGuard from "components/UI/ActionPermissionGuard";
import AngleCirculeRight from "components/Icon/AngleCirculeRight";

const MoreMenuContainer = styled.div`
    margin-left: -70px;
    display: inline-flex;
`;

const Wrapper = styled(Row)`
    border-bottom: ${global.config.border.small};
    background-color: ${(props) =>
        props.selected
            ? global.config.colors.gray9
            : global.config.colors.white};
    margin: 0;
    &:hover {
        background: ${(props) => !props.selected && global.config.colors.gray9};
    }
    ,
    &:hover #moreButton {
        margin-left: 0px;
        display: flex;
        -ms-flex-align: center;
        -webkit-align-items: center;
        -webkit-box-align: center;
        align-items: "center";
        justify-content: "center";
    }

    &:hover .rightArrow {
        path,
        circle {
            stroke: ${global.config.colors.black};
        }
    }
    &:hover .downArrow {
        path,
        circle {
            stroke: ${global.config.colors.black};
        }
    }

    .rightArrow {
        path,
        circle {
            stroke: ${global.config.colors.gray3};
        }
    }

    .downArrow {
        path,
        circle {
            stroke: ${global.config.colors.black};
        }
    }
`;

const IndentColumn = styled(Col)`
    flex: 0 0 4%;
`;

const Column = styled(Col)`
    border-right: ${global.config.border.small};
    padding: 0;
    &:last-child {
        border-right: none;
    }
    &:hover #moreButton {
        display: flex;
        -ms-flex-align: center;
        -webkit-align-items: center;
        -webkit-box-align: center;
        align-items: "center";
        justify-content: "center";
    }
`;

const WrapperEmployee = styled.div`
    display: flex;
`;

const WrapperAction = styled.div`
    display: flex;
    min-width: 1.25rem;
    width: 1.25rem;
    max-width: 1.25rem;
    align-items: center;
`;

const StyledEmployeeProfile = styled(Profile)`
    width: 100%;
`;

const EmployeeHeader = React.memo(
    ({ expanded, initialData }) => {
        const { employeeData } = useContext(EmployeeContext);
        const customGroupConsumer = useContext(CustomGroupContext);
        const contextConsumer = useContext(Context);
        const employeeGridSelection = useContext(EmployeeGridSelectionContext);
        const [drawerUserId, setDrawerUserId] = useState();

        const handleDrawerClose = () => {
            setDrawerUserId(null);
        };

        const handleClick = (userId) => {
            if (userId > 0) {
                if (drawerUserId == null) {
                    setDrawerUserId(userId);
                } else {
                    setDrawerUserId(null);
                }
            }
        };

        let employeeInfo = employeeData?.name ? employeeData : initialData;
        const realEmployee = employeeInfo.userId && employeeInfo.userId !== "0";

        const isSelectable =
            employeeGridSelection.allSelectableEmployeeIDs.includes(
                employeeInfo.userId
            );

        const isSelected = employeeGridSelection.selectedEmployeeIDs.includes(
            employeeInfo.userId
        );


        const tooltip1 = isSelected ? "Deselect Employee" : "Select Employee";
        const tooltip2 = realEmployee ? "Cannot Select Employee" : "Cannot Select TBDs";
        const tooltip = isSelectable ? tooltip1 : tooltip2;

        const handleOnClickRowCheckbox = (e) => { e.target.checked ? employeeGridSelection.addIdToSelection(employeeInfo.userId) : employeeGridSelection.removeIdFromSelection(employeeInfo.userId); };

        const fadeRow = (row) => {
            let opacity = 1;
            var interval = setInterval(function () {
                if (opacity > 0) {
                    opacity -= 0.1;
                    row.firstChild.style.backgroundColor = "rgba(128, 128, 128," + opacity + ")";
                } else {
                    row.firstChild.style.backgroundColor = "white";
                    clearInterval(interval);
                }
            }, 10);
        }

        const handleDetailClick = (id) => {
            const expandedRow = document.getElementById("div_" + id);
            // if (expandedRow){
            //     if (expanded){
            //         document.documentElement.scrollTop = document.body.scrollTop = expandedRow.dataset.scrollposition;
            //         expandedRow.dataset.scrollposition = "";
            //     } else {
            //         expandedRow.dataset.scrollposition = document.documentElement.scrollTop || document.body.scrollTop;
            //     }
            // }
            fadeRow(expandedRow);
            const divEmployee = document.getElementById("div_detail_" + getEmployeeKey(initialData));
            const divIco1 = document.getElementById("btn_ico1" + getEmployeeKey(initialData));
            const divIco2 = document.getElementById("btn_ico2" + getEmployeeKey(initialData));
            const divEmpHeader = document.getElementById("emp_header_wrapper_" + getEmployeeKey(initialData))
            if (divEmployee) {
                if (divEmployee.style.display == "none") {
                    divEmployee.style.display = "";
                    if (divIco1 && divIco2) {
                        divIco1.style.display = "";
                        divIco2.style.display = "none";
                    }
                } else {
                    divEmployee.style.display = "none";
                    divEmpHeader.classList.remove("allocationsStickyEmployee");
                    divEmpHeader.style.top = 0;
                    window.stickyHeader();
                    if (divIco1 && divIco2) {
                        divIco1.style.display = "none";
                        divIco2.style.display = "";
                    }
                }
            }
        }

        return (
            <>
                {employeeInfo?.fullName && (
                    <Wrapper id={"emp_header_wrapper_" + getEmployeeKey(employeeInfo)} className="allocEmpHeader" >
                        <IndentColumn xs={1} />
                        <Column xs={4} lg={3}>
                            <WrapperEmployee>
                                <WrapperAction>
                                    {employeeGridSelection.selectionIsEnabled ? (
                                        <ActionPermissionGuard code="AC_SELECT_MULTIPLE_EMP">
                                            <Checkbox
                                                title={tooltip}
                                                isSmallSize={true}
                                                disabled={!isSelectable}
                                                checked={isSelected}
                                                onClick={handleOnClickRowCheckbox}
                                            />
                                        </ActionPermissionGuard>
                                    ) : (
                                        <>
                                            <div id={"btn_ico1" + getEmployeeKey(employeeInfo)} onClick={() => { handleDetailClick(getEmployeeKey(employeeInfo)) }}>
                                                <DownArrow />
                                            </div>
                                            <div id={"btn_ico2" + getEmployeeKey(employeeInfo)} style={{ display: "none" }} onClick={() => { handleDetailClick(getEmployeeKey(employeeInfo)) }}>
                                                <AngleCirculeRight />
                                            </div>
                                        </>
                                    )}
                                </WrapperAction>
                                <StyledEmployeeProfile
                                    key={employeeInfo.userId}
                                    id={employeeInfo.userId}
                                    index={employeeInfo.userId}
                                    name={employeeInfo.fullName}
                                    title={employeeInfo.title}
                                    location={employeeInfo.office}
                                    image={employeeInfo.smallPhoto || undefined}
                                    departureDate={employeeInfo.departureDate}
                                    employeeStatus={employeeInfo.employeeStatus}
                                    onClick={realEmployee ? () => handleClick(employeeInfo.userId) : void 0}
                                />
                                <MoreMenuContainer>
                                    {realEmployee ? (<More employee={employeeInfo} />) : null}
                                </MoreMenuContainer>
                            </WrapperEmployee>
                        </Column>
                        <Pills
                            initialData={employeeInfo}
                        />
                        <IndentColumn xs={1} />
                    </Wrapper>
                )}
                {drawerUserId && (
                    <Context.Provider value={contextConsumer}>
                        <CustomGroupContext.Provider value={customGroupConsumer}>
                            <GenericSidebarPortal
                                open={true}
                                content={<EmployeeDrawer userId={drawerUserId} />}
                                onClose={handleDrawerClose}
                            />
                        </CustomGroupContext.Provider>
                    </Context.Provider>
                )}
            </>
        );
    }
);


export default EmployeeHeader;
