import { useState, forwardRef, useImperativeHandle, useContext } from "react";
import Modal from "react-bootstrap/Modal";
import {
    Container,
    FormGroup,
    Button as ButtonBootstrap,
} from "react-bootstrap";
import moment from "moment";
import styled from "styled-components";
import { useForm } from "react-hook-form";
import TextAreaInput from "../../UI/TextAreaInput";
import Button from "../../UI/Button";
import Text from "../../UI/Text";
import Link from "../../UI/Link";
import TextInputWithValidation from "../../UI/TextInputWithValidation";
import { useCustomGroups } from "../../../utilities/API/CustomGroups";
import { sendNotification } from "../../../utilities/Notification";
import {
    useStylesForButton,
    useStylesForModal,
    useStylesForText,
} from "../../../styles/MakeStyles";
import { BestWorkGroups, formModes } from "../../../resources/Enums";
import CustomGroupContext from "context/Allocations/CustomGroupContext";
import Tooltip from "../../UI/Tooltip";
import Loader from "components/UI/Loader";
import { useValidationError } from "hooks/Errors/useValidationError";
import EmployeeGridSelectionContext from "context/Allocations/EmployeeGridSelectionContext";
import FilterContext from "context/Allocations/FilterContext";
import Context from "context/Allocations/Context";
import IconButton from "components/UI/IconButton";
import Close from "components/Icon/Close";
import SessionContext from "context/app/SessionContext";

const ModalControl = styled.a`
    display: inline-flex;
    vertical-align: top;
    position: absolute;
`;
const Content = styled.div`
    margin: auto 3.438rem;
    margin-bottom: 7.625rem;
`;
const FormField = styled.div`
    margin: 1rem 0;
`;
const Action = styled.div`
    margin: 2.5rem 4.375rem 2.5rem 0;
    text-align: right;
    > button.MuiButton-root {
        margin-left: 1rem;
        margin-right: 0;
        min-width: 8.75rem;
        height: 2.625rem;
    }
    > button.MuiButton-root:hover {
        opacity: 0.75;
        box-shadow: none;
        background-color: ${global.config.colors.black};
    }

    position: absolute;
    bottom: 0;
    right: 0;
`;

const LoaderWrapper = styled.div`
    text-align: center;
    margin-top: 4rem;
`;

const CustomGroup = forwardRef((props, ref) => {

    const session = useContext(SessionContext);
    const isBestworkAdmin = session.groups.some(x => x.code === BestWorkGroups.BWAdmin);

    const {
        getCustomGroup,
        validateCustomGroup,
        createCustomGroup,
        updateCustomGroup,
        deleteCustomGroup,
    } = useCustomGroups();
    const { selected } = props;
    const {
        control,
        setError,
        trigger,
        formState: { errors },
    } = useForm({
        mode: "onChange",
    });
    const [show, setShow] = useState(false);
    const [title, setTitle] = useState("");
    const [mode, setMode] = useState(formModes.ADD);
    const [id, setId] = useState("");
    const [name, setName] = useState("");
    const [createdBy, setCreatedBy] = useState("");
    const [created, setCreated] = useState("");
    const [updated, setUpdated] = useState("");
    const [own, setOwn] = useState(false);
    const [isBestworkAdminGroup, setIsBestworkAdminGroup] = useState(isBestworkAdmin);
    const [description, setDescription] = useState("");
    const classes = useStylesForModal();
    const filterConsumer = useContext(FilterContext);
    const buttonClasses = useStylesForButton();
    const classesText = useStylesForText();
    const [selectedUser, setSelectedUser] = useState(null);
    const [isLoadingData, setIsLoadingData] = useState(false);
    const [updatedBy, setUpdatedBy] = useState("");

    const { handleFormErrorResponse } = useValidationError();
    const customGroupConsumer = useContext(CustomGroupContext);
    const employeeGridSelectionConsumer = useContext(
        EmployeeGridSelectionContext
    );

    const employeesConsumer = useContext(Context);

    useImperativeHandle(ref, () => ({
        add(employeeData) {
            setId("");
            setName("");
            setDescription("");
            setMode(formModes.ADD);
            setTitle("Create Custom Group");
            setShow(true);
            setSelectedUser(employeeData);
        },
        detail(id) {
            setId(id);
            handleLoad(id);
            setDetailsMode();
            setShow(true);
        },
        delete(id, name) {
            setId(id);
            setName(name);
            setMode(formModes.DELETE);
            setTitle("Delete Custom Group?");
            setShow(true);
        },
    }));

    const setInProgress = (saving, loaderId, buttonsId) => {
        let loader = document.getElementById(loaderId);
        let buttons = document.getElementById(buttonsId);

        if (saving && loader) {
            loader.style.display = "block";
        } else if (!saving && loader) {
            loader.style.display = "none";
        }

        if (saving && buttons) {
            buttons.style.display = "none";
        } else if (!saving && buttons) {
            buttons.style.display = "block";
        }
    };

    const handleClose = () => {
        setId("");
        setName("");
        setDescription("");
        setCreatedBy("");
        setCreated("");
        setUpdated("");
        setOwn(false);
        setIsBestworkAdminGroup(false);
        setMode(formModes.ADD);
        setShow(false);
    };

    const handleShow = () => {
        setShow(true);
    };

    const setDetailsMode = () => {
        setMode(formModes.DETAIL);
        setTitle("Custom Group Details");
    };

    const onCancel = () => {
        mode === formModes.UPDATE || mode === formModes.DELETE
            ? setDetailsMode()
            : handleClose();
    };

    const messageCreated = (customGroupId) => {
        const hasSelection =
            employeeGridSelectionConsumer.selectedEmployeeIDs.length > 0;

        const allSelected = employeeGridSelectionConsumer?.allSelected;

        const employeeNames =
            employeeGridSelectionConsumer.getNamesFromSelectedIDs();

        const listLength =
            employeeGridSelectionConsumer.selectedEmployeeIDs.length;

        return (
            <>
                You have created the Custom Group <b>{name}</b>
                {hasSelection ? (
                    <>
                        , and added&nbsp;
                        <b>
                            {employeeNames[0]}&nbsp;
                            {listLength > 1 && (
                                <>
                                    <span>and </span>

                                    {allSelected ? (
                                        "all filter result employees"
                                    ) : (
                                        <Tooltip
                                            placement="top"
                                            title={employeeNames
                                                .slice(1)
                                                .map((name, idx) => (
                                                    <Text
                                                        key={idx}
                                                        variant="xsmall"
                                                    >
                                                        {name}
                                                    </Text>
                                                ))}
                                        >
                                            <ButtonBootstrap
                                                variant="link"
                                                className={
                                                    buttonClasses.tooltipLink
                                                }
                                                style={{
                                                    color: global.config.colors
                                                        .white,
                                                    fontSize:
                                                        global.config.fonts
                                                            .sizes.small,
                                                }}
                                            >
                                                {`${listLength - 1} ${listLength > 2
                                                    ? "others"
                                                    : "other"
                                                    }`}
                                            </ButtonBootstrap>
                                        </Tooltip>
                                    )}
                                </>
                            )}
                            &nbsp;
                        </b>
                    </>
                ) : (
                    "."
                )}
                <Link
                    variant="secondary"
                    color={global.config.colors.white}
                    href={void 0}
                    onClick={() => {
                        window.location.href = `${window.location.href.split("?")[0]
                            }?customGroupId=${customGroupId}`;
                    }}
                >
                    View Group
                </Link>
            </>
        );
    };
    const messageUpdated = () => {
        return (
            <>
                You have updated the Custom Group <b>{name}</b>.
            </>
        );
    };
    const messageDeleted = () => {
        return (
            <>
                The Custom Group <b>{name}</b> has been deleted.
            </>
        );
    };
    const handleLoad = async function (id) {
        setIsLoadingData(true);

        await getCustomGroup([{ name: "$id", value: id }]).then((payload) => {
            setName(payload.Name);
            setDescription(payload.Description);
            setCreatedBy(payload.UserCreatorName);
            setUpdatedBy(payload.UpdatedByUserName);
            setCreated(moment(payload.CreationDateTime).format("MMMM D, YYYY"));
            setUpdated(
                moment(payload.LastUpdateDateTime).format("MMMM D, YYYY")
            );
            setOwn(payload.Own);
            setIsBestworkAdminGroup(isBestworkAdmin);
            setIsLoadingData(false);
        });
    };

    const handleSave = async function () {
        switch (mode) {
            case formModes.ADD:
                const userID = selectedUser ? (selectedUser.id !== undefined ? selectedUser.id : selectedUser.userId) : 0;
                const userIds =
                    employeeGridSelectionConsumer.selectedEmployeeIDs.length > 0
                        ? employeeGridSelectionConsumer.selectedEmployeeIDs
                        : selectedUser
                            ? [userID]
                            : [];
                const allSelected = employeeGridSelectionConsumer?.allSelected
                    ? employeeGridSelectionConsumer.allSelected
                    : false;

                const query = {
                    name,
                };
                setInProgress(
                    true,
                    "divCustomGroup_loader",
                    "divCustomGroup_buttons"
                );
                validateCustomGroup(query)
                    .then(() => {
                        createCustomGroup(
                            name,
                            description,
                            userIds,
                            allSelected,
                            filterConsumer.summaryQuery
                        )
                            .then((customGroupId) => {
                                sendNotification(
                                    undefined,
                                    messageCreated(customGroupId)
                                );
                                setInProgress(
                                    false,
                                    "divCustomGroup_loader",
                                    "divCustomGroup_buttons"
                                );
                                handleClose();
                            })
                            .then(async () => {
                                employeeGridSelectionConsumer.setSelectionIsEnabled(
                                    false
                                );
                                employeeGridSelectionConsumer.deselectAllEmployeeIDs();
                            })
                            .catch((error) => {
                                setInProgress(
                                    false,
                                    "divCustomGroup_loader",
                                    "divCustomGroup_buttons"
                                );
                                handleFormErrorResponse(error, setError);
                            });
                    })
                    .catch((error) => {
                        setInProgress(
                            false,
                            "divCustomGroup_loader",
                            "divCustomGroup_buttons"
                        );
                        handleFormErrorResponse(error, setError);
                    });
                break;
            case formModes.UPDATE:
                setShow(false);
                updateCustomGroup({
                    id: id,
                    name: name,
                    description: description,
                })
                    .then(() => {
                        if (parseInt(selected?.Id) === parseInt(id)) {
                            const selectedGroup = {
                                ...customGroupConsumer.selectedCustomGroup,
                            };
                            selectedGroup.Name = name;

                            customGroupConsumer.setSelectedCustomGroup(
                                selectedGroup
                            );
                        }
                        handleClose();
                        sendNotification(undefined, messageUpdated());
                    })
                    .catch((error) => {
                        handleFormErrorResponse(error, setError);
                    });
                break;
            default:
                break;
        }
    };
    const handleDelete = async function () {
        const loaderId = "divCustomGroup_loader_deleting";
        const buttonsId = "divCustomGroup_deleting_buttons";
        setInProgress(true, loaderId, buttonsId);
        await deleteCustomGroup([{ name: "$id", value: id }])
            .then(async () => {
                if (parseInt(selected?.Id) === parseInt(id)) {
                    customGroupConsumer.setSelectedCustomGroup(null);
                    employeesConsumer?.setEmployees([]);
                    let uri = window.location.toString();
                    if (uri.indexOf("?") > 0) {
                        let clean_uri = uri.substring(0, uri.indexOf("?"));
                        window.history.replaceState(null, null, clean_uri);
                    }
                }
                setInProgress(true, loaderId, buttonsId);
                handleClose();
                sendNotification(undefined, messageDeleted());
            })
            .catch((error) => {
                setInProgress(true, loaderId, buttonsId);
                handleFormErrorResponse(error, setError);
            });
    };

    return (
        <>
            <ModalControl onClick={handleShow}>{props.children}</ModalControl>
            <Modal
                show={show}
                onHide={handleClose}
                backdrop={global.config.modal.static}
                keyboard={global.config.modal.keyboard}
                centered
                size="sm"
                className={classes.root}
                animation={false}
            >
                <Modal.Header>
                    <Modal.Title>
                        <Text variant="xlarge">{title}</Text>
                    </Modal.Title>
                    <IconButton
                        icon={<Close />}
                        onClick={() => handleClose()}
                    />
                </Modal.Header>
                <Modal.Body>
                    <Container fluid>
                        {isLoadingData ? (
                            <LoaderWrapper>
                                <Loader height="3rem" width="3rem" />
                            </LoaderWrapper>
                        ) : (
                            <Content>
                                {(mode === formModes.ADD ||
                                    mode === formModes.UPDATE) && (
                                        <>
                                            <FormGroup>
                                                <TextInputWithValidation
                                                    required={true}
                                                    label="Custom Group Name"
                                                    control={control}
                                                    name="name"
                                                    errors={errors}
                                                    onChange={(event) =>
                                                        setName(event.target.value)
                                                    }
                                                    value={name}
                                                    defaultValue={name}
                                                ></TextInputWithValidation>
                                            </FormGroup>
                                            <FormGroup>
                                                <TextAreaInput
                                                    label="Add a description (Optional)"
                                                    name="description"
                                                    onChange={(event) =>
                                                        setDescription(
                                                            event.target.value
                                                        )
                                                    }
                                                    rows={5}
                                                    cols={5}
                                                    value={description}
                                                />
                                            </FormGroup>
                                            <Action>
                                                {
                                                    <>
                                                        <div
                                                            id="divCustomGroup_loader"
                                                            style={{
                                                                display: "none",
                                                            }}
                                                        >
                                                            <Loader
                                                                height="2rem"
                                                                width="2rem"
                                                            />
                                                        </div>
                                                        <div id="divCustomGroup_buttons">
                                                            <Link
                                                                style={{
                                                                    paddingRight:
                                                                        "20px",
                                                                }}
                                                                variant="secondary"
                                                                onClick={onCancel}
                                                            >
                                                                Cancel
                                                            </Link>
                                                            <Button
                                                                onClick={async () => {
                                                                    const valid =
                                                                        await trigger();
                                                                    valid &&
                                                                        handleSave();
                                                                }}
                                                            >
                                                                {mode ===
                                                                    formModes.ADD && (
                                                                        <>Create</>
                                                                    )}
                                                                {mode ===
                                                                    formModes.UPDATE && (
                                                                        <>Update</>
                                                                    )}
                                                            </Button>
                                                        </div>
                                                    </>
                                                }
                                            </Action>
                                        </>
                                    )}
                                {mode === formModes.DETAIL && (
                                    <>
                                        <FormField>
                                            <Text
                                                variant="xsmall"
                                                className={classesText.gray2}
                                            >
                                                Custom Group Name
                                            </Text>
                                            <Text variant="medium">{name}</Text>
                                        </FormField>
                                        <FormField>
                                            <Text
                                                variant="xsmall"
                                                className={classesText.gray2}
                                            >
                                                Description
                                            </Text>
                                            <Text variant="medium">
                                                {description}
                                            </Text>
                                        </FormField>
                                        <FormField>
                                            <Text
                                                variant="xsmall"
                                                className={classesText.gray2}
                                            >
                                                Created By
                                            </Text>
                                            <Text variant="medium">
                                                {createdBy} - {created}
                                            </Text>
                                        </FormField>
                                        {updatedBy && (
                                            <FormField>
                                                <Text
                                                    variant="xsmall"
                                                    className={
                                                        classesText.gray2
                                                    }
                                                >
                                                    Last Updated
                                                </Text>
                                                <Text variant="medium">
                                                    {updatedBy} - {updated}
                                                </Text>
                                            </FormField>
                                        )}
                                        {(own || isBestworkAdminGroup) && (
                                            <Action>
                                                <Button
                                                    className={[
                                                        buttonClasses.button,
                                                        buttonClasses.secondary,
                                                        buttonClasses.noMargin,
                                                    ]}
                                                    onClick={() => {
                                                        setMode(
                                                            formModes.DELETE
                                                        );
                                                        setTitle(
                                                            "Delete Custom Group?"
                                                        );
                                                    }}
                                                >
                                                    Delete
                                                </Button>
                                                <Button
                                                    onClick={() => {
                                                        setMode(
                                                            formModes.UPDATE
                                                        );
                                                        setTitle(
                                                            "Edit Custom Group Details"
                                                        );
                                                    }}
                                                    className={[
                                                        buttonClasses.button,
                                                        buttonClasses.noMargin,
                                                        buttonClasses.primaryButton,
                                                    ]}
                                                >
                                                    Edit
                                                </Button>
                                            </Action>
                                        )}
                                    </>
                                )}
                                {mode === formModes.DELETE && (
                                    <>
                                        <FormField>
                                            <Text variant="medium">
                                                You are about to delete the
                                                Custom Group{" "}
                                                <strong>{name}</strong>.
                                                <br />
                                                <br />
                                                Do you wish to continue?
                                            </Text>
                                        </FormField>
                                        <Action>
                                            <div
                                                id="divCustomGroup_loader_deleting"
                                                style={{ display: "none" }}
                                            >
                                                <Loader
                                                    height="2rem"
                                                    width="2rem"
                                                />
                                            </div>
                                            <div id="divCustomGroup_deleting_buttons">
                                                <Link
                                                    variant="secondary"
                                                    onClick={onCancel}
                                                    style={{
                                                        paddingRight: "32px",
                                                    }}
                                                >
                                                    Cancel
                                                </Link>
                                                <Button onClick={handleDelete}>
                                                    Yes, Delete
                                                </Button>
                                            </div>
                                        </Action>
                                    </>
                                )}
                            </Content>
                        )}
                    </Container>
                </Modal.Body>
            </Modal>
        </>
    );
});

export default CustomGroup;
